import { CanDeactivateGuard, getNewComponentId } from 'ngx-myia-core';
import { DialogTemplateStore } from './dialogTemplatesStore';
import { DialogContainerComponent } from './dialogContainer';
import { ModalDialogComponent } from './modalDialogComponent';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Observable, Subscriber, isObservable } from 'rxjs';

import {
  Injectable, ComponentRef, EmbeddedViewRef, ApplicationRef, ComponentFactoryResolver, Injector, TemplateRef,
} from '@angular/core';
import { ModalInstance } from 'ngx-smart-modal/src/services/modal-instance';

export interface IModalDialogOptions<T> {
  dialogData?: T;
  identifier?: string;
  customClass?: string;
  dismissable?: boolean;
}

@Injectable({
  providedIn: 'root'
}) // dont use providedIn: 'root' -> must be instantiated in each feature module to have access to feature module entryComponents (https://github.com/angular/angular/issues/14324)
export class DialogManager {

  private readonly toastContainerElement: HTMLElement;
  private readonly toastContainerRef: ComponentRef<DialogContainerComponent>;

  constructor(private _dialogTemplateStore: DialogTemplateStore, private _ngxSmartModalService: NgxSmartModalService, private _appRef: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, canDeactivateGuard: CanDeactivateGuard) {
    this.toastContainerRef = this.createComponentRef(DialogContainerComponent);
    this.toastContainerElement = this.getDomElementFromComponentRef(this.toastContainerRef);
    this.addChild(this.toastContainerElement);

    canDeactivateGuard.registerDeactivationHandler(this.canDeactivate.bind(this));
  }

  showModalDialog<TDialogData, TResult>(dialogTemplate: TemplateRef<any> | string, options?: IModalDialogOptions<TDialogData>): ModalDialogComponent<TDialogData, TResult> {
    let template = dialogTemplate;
    if (typeof dialogTemplate === 'string') {
      template = this._dialogTemplateStore.getTemplateByName(dialogTemplate);
    }
    if (!template) {
      throw new Error(`Dialog '${dialogTemplate}' not found!`);
    }
    const dialog = this.createComponentRef(ModalDialogComponent);
    dialog.instance.identifier = (options && options.identifier) || getNewComponentId();
    dialog.instance.customClass = (options && options.customClass) || '';
    dialog.instance.dismissable = !!(options && options.dismissable);
    dialog.instance.dialogData = options && options.dialogData;
    dialog.instance.dialogTemplate = template;

    const dialogElement = this.getDomElementFromComponentRef(dialog);
    this.addChild(dialogElement, this.toastContainerElement);
    const ngxModal = dialog.instance.ngxModal;

    dialog.instance.result = new Observable((observer: Subscriber<TResult>) => {
      ngxModal.onOpen.subscribe((modal: NgxSmartModalComponent) => {
        console.log(`${modal.identifier} modal opened!`);
      });
      ngxModal.onAnyCloseEvent.subscribe((modal: NgxSmartModalComponent) => {
        if (!dialog.instance.destroyed) {
          const result = modal.hasData() ? modal.getData() : null;
          console.log(`${modal.identifier} modal closed: ${result}`);
          dialog.instance.destroyed = true;
          observer.next(result);
          observer.complete();
          this.destroyRef(dialog, 0);
        } else {
          console.log(`${modal.identifier} modal already closed.`);
        }
      });
      dialog.instance.destroyed = false;
      ngxModal.dialogComponent = dialog.instance;
      ngxModal.setData(undefined, true); // reset dialog data (result)
      ngxModal.open();
    });

    return dialog.instance;
  }

  closeCurrentDialog(): Observable<boolean> | boolean {
    const opened = (this._ngxSmartModalService.getOpenedModals() || []).filter(m => {
      const modalDialogComponent = this.getModalDialogComponent(m);
      // filter destroyed dialogs
      return !modalDialogComponent || !modalDialogComponent.destroyed;
    });
    if (opened.length) {
      const topDialog = opened[opened.length - 1];
      const dialogComponent = this.getModalDialogComponent(topDialog);
      if (dialogComponent && dialogComponent.canDeactivate) {
        const dialogGuardResult = dialogComponent.canDeactivate();
        if (isObservable<boolean>(dialogGuardResult)) {
          dialogGuardResult.subscribe(result => {
            if (result) {
              topDialog.modal.close();
            }
          });
        } else {
          if (dialogGuardResult) {
            topDialog.modal.close();
          }
        }
        return dialogGuardResult;
      } else {
        topDialog.modal.close();
        return false;
      }
    }
    return true;
  }

  isDialogOpened() {
    return this._ngxSmartModalService.getModalStackCount() > 0;
  }

  private createComponentRef(component: any): ComponentRef<any> {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    this._appRef.attachView(componentRef.hostView);
    return componentRef;
  }

  private getDomElementFromComponentRef(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
  }

  private addChild(child: HTMLElement, parent: HTMLElement = document.body) {
    parent.appendChild(child);
  }

  private destroyRef(componentRef: ComponentRef<any>, delay: number) {
    setTimeout(() => {
      this._appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, delay);
  }

  private canDeactivate(): Observable<boolean> | boolean {
    return this.closeCurrentDialog();
  }

  private getModalDialogComponent(m: ModalInstance): ModalDialogComponent<any, any> {
    return m.modal && (m.modal as any).dialogComponent as ModalDialogComponent<any, any>;
  }
}

import { Injectable, TemplateRef } from '@angular/core';

@Injectable({providedIn: 'root'})
export class DialogTemplateStore {
  private _templates = {};

  addTemplate(templateName: string, template: TemplateRef<any>): void {
    const templateHostName = template.elementRef.nativeElement.parentNode.tagName;
    if (this._templates[templateName]) {
      if (this._templates[templateName].templateHostName === templateHostName) {
        // multiple instances of same component registering same template
        this._templates[templateName].refsCount++;
        return;
      }
      throw new Error(`Dialog with name '${templateName}' is already registered!`);
    }
    this._templates[templateName] = template;
    this._templates[templateName].templateHostName = templateHostName;
    this._templates[templateName].refsCount = 1;
  }

  removeTemplate(templateName: string): void {
    if (this._templates[templateName]) {
      this._templates[templateName].refsCount--;
      if (!this._templates[templateName].refsCount) {
        delete this._templates[templateName];
      }
    } else {
      throw new Error(`Dialog with name '${templateName}' is not registered!`);
    }
  }

  getTemplateByName(name: string): TemplateRef<any> {
    return this._templates[name];
  }

}

import { NgModule } from '@angular/core';
import { CoreModule } from 'ngx-myia-core';
import { DialogContainerComponent } from './dialogContainer';
import { DialogIdentifierDirective } from './dialogIdentifier';
import { ModalDialogComponent } from './modalDialogComponent';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        NgxSmartModalModule.forRoot(),
    ],
    declarations: [
        DialogContainerComponent,
        DialogIdentifierDirective,
        ModalDialogComponent,
    ],
    entryComponents: [
        DialogContainerComponent,
        ModalDialogComponent
    ],
    exports: [
        ModalDialogComponent,
        DialogIdentifierDirective
    ]
})
export class DialogModule {}
